/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aid-innovation-guide",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aid-innovation-guide",
    "aria-label": "hearing aid innovation guide permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aid Innovation Guide"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids have been quick to leverage faster processors and new technologies to deliver devices that are more effective and easier to use. Just think about how much better your smartphone is today than the one you had 5 years ago. Hearing aids are an investment in your quality of life. So you want to make sure the technology is up-to-date and includes modern-day features that support your lifestyle."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aid-technology-keeps-getting-better-and-better",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aid-technology-keeps-getting-better-and-better",
    "aria-label": "hearing aid technology keeps getting better and better permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing aid technology keeps getting better and better"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Did you know hearing aids are 50x faster than the devices from 5 years ago? Every year, processors get faster and algorithms become more sophisticated. Compared to the older, outdated models, these advancements in technology provide better speech understanding and more natural sound. Additional benefits include enhanced feedback cancellation, wind noise reduction, surround sound, and more. With all these advancements, there’s a lot for hearing aid wearers to be excited about."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "wireless-bluetooth-connectivity",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#wireless-bluetooth-connectivity",
    "aria-label": "wireless bluetooth connectivity permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Wireless Bluetooth connectivity"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This is arguably one of the most popular features of modern hearing aids. Older models couldn’t connect to smartphones or TVs, and it was difficult to talk on the phone without your hearing aid getting in the way. However, many new models allow you to stream phone calls, music, TV audio and more directly to your hearing aids without having to wear additional accessories. Some models allow you to answer incoming calls by simply pushing a tiny button on the hearing aid."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "rechargeability",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rechargeability",
    "aria-label": "rechargeability permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rechargeability"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You probably know how annoying it can be to change those tiny hearing aid batteries. Thankfully, you don’t have to fumble around with dead batteries anymore because many of the newer devices are fully rechargeable. Simply plug the charging case to a power source and places the devices inside. Leave them charging overnight and they’ll be ready to use the next day. But all you really need is 3 – 4 hours of charging to bring the battery life of your hearing aids back to 100%."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "smartphone-apps",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#smartphone-apps",
    "aria-label": "smartphone apps permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Smartphone apps"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Want to fine-tune your hearing aids and control the way you hear? There’s an app for that! Many of the major manufacturers now offer hearing aids that you can control with your Android or iPhone. Adjust the volume, change hearing programs, and personalize your settings with a smartphone app without visiting your local hearing care provider."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "automation-machine-learning-and-artificial-intelligence",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#automation-machine-learning-and-artificial-intelligence",
    "aria-label": "automation machine learning and artificial intelligence permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Automation, machine learning, and artificial intelligence"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids are now leveraging advanced software programs that can learn your preferred hearing settings in different environments, predict the most effective program for you in a specific environment, and automatically adjust the programs accordingly. In other words, advanced hearing aids can automate the things you used to do manually and research shows it does a better job. The newest, luxury-level devices have apps that can even transcribe phone conversations in real-time, translate speech from different languages, and track your physical activity."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "ready-to-take-your-hearing-to-the-next-level",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ready-to-take-your-hearing-to-the-next-level",
    "aria-label": "ready to take your hearing to the next level permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ready to take your hearing to the next level?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re thinking about upgrading or testing the latest technology, make sure you’ve already signed up for a 30-day trial. If you haven’t signed up yet, just click the button below to get started."), "\n", React.createElement(ButtonCta, {
    copy: "Sign up for a 30-day trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
